import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AdminPanelLock, CommonUser, CommonUsers, ExternalDocument } from './common-api.interface';
import { Observable } from 'rxjs';
import { Dictionary } from '../../../common-types';
import { FieldApproval } from '../../models/approval.model';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  getUsers(): Observable<CommonUsers> {
    return this.httpClient.get<CommonUsers>(`${environment.api.baseUri}/Users`);
  }

  getCurrentUser(): Observable<CommonUser> {
    return this.httpClient.get<CommonUser>(`${environment.api.baseUri}/User`);
  }

  getUserById(id:string): Observable<CommonUser> {
    return this.httpClient.get<CommonUser>(`${environment.api.baseUri}/User/${id}`);
  }

  getAdminLock(): Observable<AdminPanelLock> {
    return this.httpClient.get<AdminPanelLock>(`${environment.api.commonBaseUri}/AdminPanel/EditionLock`);
  }

  editLock(isLocked: boolean): Observable<void> {
    const params = new HttpParams().set('lockStatus', isLocked);

    return this.httpClient.put<void>(
      `${environment.api.commonBaseUri}/AdminPanel/EditionLock`,
      {},
      { params },
    );
  }

  getCompaniesDictionary(): Observable<Dictionary[]> {
    return this.httpClient.get<Dictionary[]>(`${environment.api.commonBaseUri}/Dictionary/Companies`);
  }

  getScpiApprovals(sharedCoreProjectInformationId: number) {
    const params = new HttpParams().set('sharedCoreProjectInformationId', sharedCoreProjectInformationId);

    return this.httpClient.get<FieldApproval[]>(`${environment.api.ppmBaseUri}/SharedCoreProjectInformation/FormApprovalInfo`, {
      params,
    });
  }

  getExternalDocuments(projectSystemId: number) {
    const params = new HttpParams().set('projectSystemId', projectSystemId);

    return this.httpClient.get<ExternalDocument[]>(`${ environment.api.commonBaseUri }/ExternalDocument`, {
      params,
    });
  }

  createExternalDocument(externalDocument: Omit<ExternalDocument, 'Id' | 'Version'>) {
    return this.httpClient.post<ExternalDocument>(`${ environment.api.commonBaseUri }/ExternalDocument`, externalDocument);
  }

  updateExternalDocument(externalDocument: ExternalDocument) {
    return this.httpClient.put<ExternalDocument>(`${ environment.api.commonBaseUri }/ExternalDocument`, externalDocument);
  }

  removeExternalDocument(externalDocumentId: number) {
    const params = new HttpParams().set('documentId', externalDocumentId);

    return this.httpClient.delete(`${ environment.api.commonBaseUri }/ExternalDocument`, {
      params,
    });
  }

  exportUsersToExcel(userIds: number[]) {
    return this.httpClient.post<number[]>(`${ environment.api.baseUri }/Users/Excel`, {
      Users: userIds,
    }, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'arraybuffer',
    });
  }
}
