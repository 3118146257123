export enum SdiStatus {
  ALL,
  DRAFT,
  SUBMITTED,
  REJECTED,
  APPROVED,
}

export const SdiStatusLabels: Record<SdiStatus | number, string> = {
  [SdiStatus['DRAFT']]: $localize`Draft`,
  [SdiStatus['SUBMITTED']]: $localize`Submitted for Review`,
  [SdiStatus['REJECTED']]: $localize`Changes Required`,
  [SdiStatus['APPROVED']]: $localize`Approved`,
  [SdiStatus['ALL']]: $localize`All`,
};
