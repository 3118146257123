import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { TaRole } from 'src/shared/models/user-roles.model';
import { CommonApiService } from 'src/shared/services/api/common-api.service';
import { TaApiService } from './ta-api.service';

@Injectable({
  providedIn: 'root',
})
export class TaRolesService {
  private readonly refetch$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith(void {}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  readonly rights$ = this.user$.pipe(
    map(({ TaRoles }) => TaRoles ?? []),
    shareReplay(1),
  );

  constructor(
    private readonly commonApiService: CommonApiService,
    private readonly taApiService: TaApiService,
  ) {}

  hasRole(role: TaRole): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights?.some(right => right.RoleId === role)),
    );
  }

  refetch() {
    this.refetch$.next(void {});
  }

  public getExternalFieldsForEditExternalRole = (): Observable<string[]> => this.taApiService.getExternalFieldsForEditExternalRole();
}
