import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HsesRolesService } from './hses-roles.service';

@Injectable({
  providedIn: 'root',
})
export class HsesPrivilegesModuleService {
  readonly isHSESEnabled$ = this.hsesRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  constructor(private hsesRolesService: HsesRolesService) {}
}
