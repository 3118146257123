import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { PpmRolesService } from '../../shared/services/ppm-roles.service';

@Injectable({
  providedIn: 'root',
})
export class PpmPrivilegesModuleService {
  readonly isPPMEnabled$ = this.ppmRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  constructor(private ppmRolesService: PpmRolesService) {}
}
