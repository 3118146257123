import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { Mapper, ProjectLockStatus } from '../../common-types';
import { ProjectType } from '../models/project-type.model';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  readonly activeRoute$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(null),
    map(() => {
      let route = this.router.routerState.root;

      while (route.firstChild) {
        route = route.firstChild;
      }

      return route;
    }),
    shareReplay(1),
  );

  readonly activeRouteData$ = this.activeRoute$.pipe(
    switchMap(route => combineLatest(route.pathFromRoot.map(({ data }) => data))),
    map(paths => paths.reduce((acc, data) => ({ ...acc, ...data }), {})),
    shareReplay(1),
  );

  readonly activeProjectId$ = this.activeRoute$.pipe(
    switchMap(route => combineLatest(route.pathFromRoot.map(({ params }) => params))),
    map(paths => paths.reduce((acc, params) => ({ ...acc, ...params }), {})),
    map(params => params[ 'id' ] as string),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly projectType$: Observable<ProjectType> = this.activeRoute$.pipe(
    map(() => {
      const currentUrl = this.router.url;
      if (currentUrl.includes('sdi')) return ProjectType.SDI;
      else if (currentUrl.includes('ppm')) return ProjectType.PPM;
      else if (currentUrl.includes('hses')) return ProjectType.HSES;
      else if (currentUrl.includes('ta/commitments')) return ProjectType.TA_COMMITMENTS;
      else if (currentUrl.includes('ta/funding-instruments')) return ProjectType.TA_FUNDING_INSTRUMENTS;
      else if (currentUrl.includes('data-admin')) return ProjectType.DATA_ADMIN;
      else return ProjectType.ADMIN;
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isPpmProject$ = this.projectType$.pipe(
    map(projectType => projectType === ProjectType.PPM),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isSdiProject$ = this.projectType$.pipe(
    map(projectType => projectType === ProjectType.SDI),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isHsesProject$ = this.projectType$.pipe(
    map(projectType => projectType === ProjectType.HSES),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isTaCommitmentsProject$ = this.projectType$.pipe(
    map(projectType => projectType === ProjectType.TA_COMMITMENTS),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isTaFundingInstrumentsProject$ = this.projectType$.pipe(
    map(projectType => projectType === ProjectType.TA_FUNDING_INSTRUMENTS),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectNew$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'new');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectView$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'view');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectEdit$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'edit');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectApproval$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'approval');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly isProjectReview$: Observable<boolean> = this.activeRoute$.pipe(
    switchMap((activatedRoute: ActivatedRoute) => activatedRoute.url),
    map((urlSegments: UrlSegment[]) => {
      return urlSegments.some((urlSegment: UrlSegment) => urlSegment.path === 'review');
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly activeTab$: Observable<string> = this.activeRouteData$.pipe(
    map(data => data['tabName'] as string),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly mapper$ = this.activeRouteData$.pipe(
    map(data => data[ 'mapper' ] as Mapper),
    shareReplay(1),
  );

  readonly dynamicDataApi$ = this.activeRouteData$.pipe(
    map(data => (data[ 'dynamicDataApi' ] as Record<string, string> | undefined)),
    shareReplay(1),
  );

  readonly multiselectDictionaries$ = this.activeRouteData$.pipe(
    map(data => (data[ 'multiselectDictionaries' ] as string[] | undefined)),
    shareReplay(1),
  );

  readonly projectLocked$ = this.activeRouteData$.pipe(
    map(data => {
      const projectLockedStatus = data['isProjectLocked'] as ProjectLockStatus;

      return {
        IsLocked: projectLockedStatus?.IsLocked ?? false,
        UserName: projectLockedStatus?.UserName ?? '',
      };
    }),
    shareReplay(1),
  );

  constructor(
    private readonly router: Router,
  ) {}
}
