import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { TaRolesService } from './ta-roles.service';

@Injectable({
  providedIn: 'root',
})
export class TaPrivilegesModuleService {
  readonly isTAEnabled$ = this.taRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  constructor(private taRolesService: TaRolesService) {}
}
