import { Injectable } from '@angular/core';
import { map, shareReplay, startWith } from 'rxjs';
import { CommonApiService } from './api/common-api.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class CommonPrivilegesModuleService {
  readonly isAdminEnabled$ = this.userService.user$.pipe(
    map(user => user.IsAdmin),
    startWith(void {}),
    shareReplay(1),
  );

  readonly isDataAdminEnabled$ = this.commonApiService.getCurrentUser().pipe(
    map(user => {
      const superPrivilegeUsers = [
        'mtb@spyro-soft.com',
        'gie@spyro-soft.com',
        'anna.palosz@unravel.cc',
        'aso@spyro-soft.com',
        'mus@spyro-soft.com',
      ];

      return superPrivilegeUsers.includes(user.UserEmail);
    }),
  );

  constructor(
    private readonly commonApiService: CommonApiService,
    private readonly userService: UserService,
  ) {}
}
