import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dictionary } from 'src/common-types';
import { environment } from 'src/environments/environment.dev';
import { Roles } from 'src/shared/services/api/common-api.interface';
import { TaUserRights } from '../models/ta-user-rights.model';

@Injectable({
  providedIn: 'root',
})
export class TaApiService {
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly httpClient: HttpClient) {}

  public getTaRoles(): Observable<Roles[]> {
    return this.httpClient.get<Roles[]>(`${environment.api.taBaseUri}/Rights/RoleDefinitions`);
  }

  public saveUsers(params: TaUserRights[]): Observable<void> {
    return this.httpClient.put<void>(`${environment.api.taBaseUri}/Rights/UserRights`, params);
  }

  public getDictionary(dictionaryName: string) {
    const params = new HttpParams().set('dictionaryName', dictionaryName);

    return this.httpClient.get<Dictionary[]>(`${environment.api.taBaseUri}/Dictionary`, {
      params,
    });
  }

  public getExternalFieldsForEditExternalRole(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.api.taBaseUri}/ExternalFields`);
  }
}
