import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'pidg-app',
  templateUrl: './pidg-app.component.html',
  styleUrls: [ './pidg-app.component.css' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PidgApp implements OnInit {
  isIframe = false;

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
