import { Injectable } from '@angular/core';
import { CommonApiService } from './services/api/common-api.service';
import { shareReplay, startWith, Subject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly refetch$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith({}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  constructor(
    private readonly commonApiService: CommonApiService,
  ) {}

  refetch() {
    this.refetch$.next(void {});
  }
}
