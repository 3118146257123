import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { SdiRolesService } from './sdi-roles.service';

@Injectable({
  providedIn: 'root',
})
export class SdiPrivilegesModuleService {
  readonly isSDIEnabled$ = this.sdiRolesService.rights$.pipe(
    map(rights => rights.length > 0),
  );

  constructor(private sdiRolesService: SdiRolesService) {}
}
