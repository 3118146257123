import ENV from '../../env';

export const environment = {
  production: false,
  aad: {
    tenantId: ENV.AAD_TENANT_ID,
    clientId: ENV.AAD_CLIENT_ID,
    redirectUri: '/auth',
    postLogoutRedirectUri: '/logout',
    loginFailedRoute: '/login-failed',
    graphUri: 'https://graph.microsoft.com/v1.0/me',
  },
  api: {
    clientId: ENV.API_CLIENT_ID,
    baseUri: ENV.API,
    sdiBaseUri: ENV.API_SDI,
    sdiProjectsUri: `${ ENV.API_SDI }/SdiProject/FilterProjects`,
    sdiDictionaryUri: `${ ENV.API_SDI }/Dictionary`,
    sdiUsersUri: `${ ENV.API_SDI }/User`,
    sdiValidationFieldsUri: `${ ENV.API_SDI }/User/RequiredValidationFields`,
    ppmBaseUri: `${ ENV.API_PPM }`,
    ppmProjectsUri: `${ ENV.API_PPM }/PpmProject/FilterProjects`,
    commonBaseUri: `${ ENV.API_COMMON }`,
    hsesBaseUri: `${ ENV.API_HSES }`,
    hsesProjectsUri: `${ ENV.API_HSES }/HsesProject/FilterProjects`,
    taBaseUri: `${ ENV.API_TA }`,
    taCommitmentsUri: `${ ENV.API_TA }/Commitment/FilterCommitments`,
    taFundingInstrumentsUri: `${ ENV.API_TA }/FundingInstrument/FilterFundingInstruments`,
  },
  app: {
    envName: 'DEV',
  },
};
