import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.dev';
import { ArrayItem, FieldDefinition } from '../../shared/utils';
import { BehaviorSubject, catchError, combineLatest, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { ApiResponse, Dictionary, Filters, History, ProjectLockStatus } from '../../common-types';
import { DEFAULT_PAGE_SIZE } from '../../shared/consts';
import { ProjectsListItem } from '../../shared/utils-models';
import { DownloadFileService } from '../../shared/services/download-file.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Router } from '@angular/router';
import { SdiApiUserToChangeRole } from './sdi-roles.interface';
import { Comment } from '../../comments/comments.model';
import { SdiStatus } from '../models/sdi-status.model';
import { SdiTabApiFormType, SdiTabName } from '../models/sdi-tabs.model';
import { Roles, Scpi } from '../../shared/services/api/common-api.interface';
import { SdiApiProject, SdiNewVersion, SdiVersion } from '../models/sdi-api.model';

@Injectable({
  providedIn: 'root',
})
export class SdiApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly downloadFile: DownloadFileService,
    private readonly router: Router,
  ) {}

  readonly controls = Object.freeze({
    sort$: new BehaviorSubject<{ active: string, direction: 'asc' | 'desc' | '' }>({
      active: '',
      direction: '',
    }),
    page$: new BehaviorSubject<{ pageIndex: number, pageSize: number }>({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    }),
    filter$: new BehaviorSubject<Filters<ProjectsListItem, 'multi'>>({}),
    status$: new BehaviorSubject<SdiStatus>(SdiStatus.ALL),
    rowCount$: new BehaviorSubject<number>(0),
  });

  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly refetch$ = new BehaviorSubject<boolean>(false);

  readonly projectsData$ = combineLatest([
    this.controls.page$,
    this.controls.sort$,
    this.controls.filter$,
    this.controls.status$,
    this.refetch$,
  ]).pipe(
    switchMap(([
      { pageSize, pageIndex },
      sort,
      filter,
      status,
    ]) => {
      this.loading$.next(true);

      return this.httpClient
        .post<ApiResponse<'/Sdi/SdiProject/FilterProjects', 'post'>>(environment.api.sdiProjectsUri, {
          pageSize,
          page: pageIndex + 1,
          Countries: filter.ProjectCountryId ?? [],
          Companies: filter.CompanyId ?? [],
          Sectors: filter.SectorId ?? [],
          OrderBy: sort.active,
          Name: filter.Name || '',
          Descending: sort.direction === 'desc',
          StepId: status || null,
        })
        .pipe(
          tap(({ RowCount }) => this.controls.rowCount$.next(RowCount ?? 0)),
          tap(() => this.loading$.next(false)),
          tap(() => {
            if (this.refetch$.value) this.refetch$.next(false);
          }),
        );
    }),
  );

  readonly projectsList$ = this.projectsData$.pipe(
    map(projects => projects.Results
      ?.map(({ IsLockedSince, ...result }) => ({
        ...result,
        IsLockedSince: (IsLockedSince ?? 0) * 1000,
      })) ?? [],
    ),
    startWith([]),
  );

  readonly pageInfo$ = combineLatest([this.controls.page$, this.controls.rowCount$]).pipe(
    map(([{ pageIndex, pageSize }, rowCount]) => ({
      pageIndex,
      pageSize,
      length: rowCount,
    }),
    ),
  );

  readonly projectsToExcel$ = this.httpClient.get(`${environment.api.sdiBaseUri}/Excel`, {
    responseType: 'arraybuffer',
  }).pipe(
    tap((data) => {
      this.downloadFile.downloadExcel(data, 'SDI Projects export');
    }),
  );

  readonly approvedProjectsToExcel$ = this.httpClient.get(`${environment.api.sdiBaseUri}/Excel/ApprovedProjects`, {
    responseType: 'arraybuffer',
  }).pipe(
    tap((data) => {
      this.downloadFile.downloadExcel(data, 'Approved SDI Projects export');
    }),
  );

  getProject(projectId: string) {
    const params = new HttpParams().set('projectId', projectId);

    return this.httpClient.get<SdiApiProject>(`${environment.api.sdiBaseUri}/SdiProject/${projectId}`, { params });
  }

  getProjectTab<T extends SdiTabName>(projectId: number, tabName: T) {
    const params = new HttpParams().set('projectId', Number(projectId));

    return this.httpClient.get<SdiTabApiFormType<T>>(`${environment.api.sdiBaseUri}/${tabName}`, { params });
  }

  updateFormTab(tabName: SdiTabName, payload: FieldDefinition[], projectId: string) {
    const params = new HttpParams().set('projectId', projectId);

    return this.httpClient.put(`${ environment.api.sdiBaseUri }/${ tabName }`, payload, { params });
  }

  addDatapoint(projectId: string, apiName: string, dynamicDataApi: string, payload: ArrayItem[]) {
    return this.httpClient.post(`${environment.api.sdiBaseUri}/${apiName}/${projectId}/${dynamicDataApi}`, payload);
  }

  updateDatapoint(projectId: string, apiName: string, dynamicDataApi: string, payload: FieldDefinition[]) {
    const params = new HttpParams().set('projectId', projectId);

    return this.httpClient.put(`${environment.api.sdiBaseUri}/${apiName}/${projectId}/${dynamicDataApi}`, payload, { params: params });
  }

  removeDatapoint(projectId: string, apiName: string, dynamicDataApi: string, itemId: string) {
    return this.httpClient.delete(`${environment.api.sdiBaseUri}/${apiName}/${projectId}/${dynamicDataApi}/${itemId}`);
  }

  updateStatus(projectId: string, status: SdiStatus) {
    const params = new HttpParams().set('statusId', String(status));

    return this.httpClient.put<ApiResponse<'/Sdi/SdiProject/{projectId}/Status', 'get'>>(
      `${environment.api.sdiBaseUri}/SdiProject/${projectId}/Status`,
      {},
      { params },
    );
  }

  validateSdiProject(projectId: string) {
    return this.httpClient.get<string[]>(`${environment.api.sdiBaseUri}/SdiProject/${projectId}/Validate`);
  }

  getRequiredFields() {
    return this.httpClient.get<ApiResponse<'/Sdi/User/RequiredValidationFields', 'get'>>(environment.api.sdiValidationFieldsUri);
  }

  getDictionary(dictionaryName: string) {
    return this.httpClient.get<Dictionary[]>(environment.api.sdiDictionaryUri, {
      params: {
        dictionaryName,
      },
    });
  }

  isProjectLocked(projectId: string) {
    return this.httpClient.get<ProjectLockStatus>(`${environment.api.sdiBaseUri}/SdiProject/${projectId}/EditionLock`);
  }

  lockProject(projectId: string) {
    return this.httpClient.post(`${environment.api.sdiBaseUri}/SdiProject/${projectId}/EditionLock?lockStatus=true`, {}).pipe(
      catchError(() => fromPromise(this.router.navigate(['projects', projectId, 'view']))),
    );
  }

  unlockProject(projectId: string) {
    return this.httpClient.post(`${environment.api.sdiBaseUri}/SdiProject/${projectId}/EditionLock?lockStatus=false`, {}).pipe(
      catchError(() => fromPromise(this.router.navigate(['projects', projectId, 'view']))),
    );
  }

  saveUsers(params: SdiApiUserToChangeRole[]) {
    return this.httpClient.put<void>(`${environment.api.sdiBaseUri}/Users`, params);
  }

  getHistory(tabName: SdiTabName, projectId: number) {
    return this.httpClient.get<History[]>(`${environment.api.sdiBaseUri}/${ tabName }/${ projectId }/history`);
  }

  // data for ProjectReference tab - returns information about project status changes
  getProjectHistory(projectId: number) {
    return this.httpClient.get<History[]>(`${environment.api.sdiBaseUri}/SdiProject/${ projectId }/history`);
  }

  downloadHistory(projectId: string) {
    return this.httpClient.get(`${environment.api.sdiBaseUri}/History/${projectId}`, { responseType: 'arraybuffer' }).pipe(
      tap((data) => this.downloadFile.downloadExcel(data, `Project_${projectId}_history`)),
    );
  }

  getComments(projectId: number, tabName: string, fieldName: string): Observable<Comment[]> {
    return this.httpClient.get<Comment[]>(
      `${environment.api.sdiBaseUri}/${tabName}/${projectId}/Comment/${fieldName}`,
    );
  }

  addComment(projectId: string, tabName: string, fieldName: string, comment: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.api.sdiBaseUri}/${tabName}/${projectId}/Comment/${fieldName}`,
      {
        Comment: comment,
      },
    );
  }

  getSdiRoles() {
    return this.httpClient.get<Roles[]>(`${environment.api.sdiBaseUri}/User/UserRights/Roles`);
  }

  getSdiReviewOnlyFields() {
    return this.httpClient.get<string[]>(`${environment.api.sdiBaseUri}/User/ExcludedFields`);
  }

  getScpi(projectId: number) {
    const params = new HttpParams().set('sdiProjectId', projectId);

    return this.httpClient.get<Scpi>(`${ environment.api.sdiBaseUri }/SharedCoreProjectInformation`, {
      params,
    });
  }

  getScpiComments(scpiId: number, fieldName: string) {
    return this.httpClient.get<Comment[]>(`${environment.api.sdiBaseUri}/SharedCoreProjectInformation/${scpiId}/Comment/${fieldName}`);
  }

  getScpiHistory(scpiId: number) {
    return this.httpClient.get<History[]>(`${environment.api.sdiBaseUri}/SharedCoreProjectInformation/${scpiId}/history`);
  }

  addScpiComment(scpiId: number, fieldName: string, comment: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.api.sdiBaseUri}/SharedCoreProjectInformation/${scpiId}/Comment/${fieldName}`,
      { Comment: comment },
    );
  }

  createNewVersion(projectId: number) {
    return this.httpClient.post<SdiNewVersion>(`${environment.api.sdiBaseUri}/SdiProject/${projectId}/NewVersion`, undefined);
  }

  getVersions(projectId: number) {
    return this.httpClient.get<SdiVersion[]>(`${ environment.api.baseUri }/Snapshots/Sdi/${ projectId }/Versions`);
  }
}
