import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  downloadExcel(data: ArrayBuffer, name: string) {
    const blobTest = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const href = window.URL.createObjectURL(blobTest);
    const a = document.createElement('a');

    a.href = href;
    a.download = `${name}.xlsx`;
    a.click();
  }
}
