import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { shareReplay } from 'rxjs';
import { environment } from '../../environments/environment';
import { ADUser } from '../azure.model';

@Injectable()
export class AadUserService {
  readonly profile$ = this.http.get<ADUser>(environment.aad.graphUri).pipe(
    shareReplay(1),
  );

  constructor(private readonly http: HttpClient, private authService: MsalService) {}

  logout(): void {
    this.authService.logoutRedirect();
  }
}
