import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { HsesRole } from 'src/shared/models/user-roles.model';
import { CommonApiService } from '../../shared/services/api/common-api.service';

@Injectable({
  providedIn: 'root',
})
export class HsesRolesService {
  private readonly refetch$ = new Subject<void>();

  readonly user$ = this.refetch$.pipe(
    startWith(void {}),
    switchMap(() => this.commonApiService.getCurrentUser()),
    shareReplay(1),
  );

  readonly rights$ = this.user$.pipe(
    map(({ HsesRoles }) => HsesRoles ?? []),
    shareReplay(1),
  );

  constructor(private readonly commonApiService: CommonApiService) {}

  hasRole(role: HsesRole, companyId: number | null = null): Observable<boolean> {
    return this.rights$.pipe(
      map(rights => rights?.some(right =>
        right.CompanyId === companyId && right.RoleId === role,
      )),
    );
  }

  refetch() {
    this.refetch$.next(void {});
  }
}
